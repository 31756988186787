import Navbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";
const OnlineResourses = () => {
  return (
    <>
      <Navbar />
      <h1>Online Resourses</h1>
      <Footer />
    </>
  );
};

export default OnlineResourses;
